import { Appointment, Project, useAbilities } from '@aposphaere/core-kit'
import { SectionCard } from '@aposphaere/ui-components'
import React, { useState } from 'react'
import AppointmentsCardItem, { AppointmentsCardItemWithPresentation } from './AppointmentsCardItem'

export type AppointmentsCardProps = {
  appointments: Appointment[]
  title: string
  showAddButton?: boolean
  showAllEntries: boolean
  canEditDelete?: boolean
  projects: Project[]
  presentationsCouldBe?: boolean
}

const AppointmentsCard: React.FunctionComponent<AppointmentsCardProps> = ({
  appointments,
  title,
  showAddButton,
  showAllEntries,
  canEditDelete,
  projects,
  presentationsCouldBe = false,
}) => {
  const abilities = useAbilities()
  const [expanded, setExpanded] = useState<boolean>(false)
  return (
    <SectionCard
      isExpanded={expanded}
      title={title}
      badgeValue={appointments.length}
      showAllEntries={showAllEntries}
      showAddButton={showAddButton}
      setExpanded={setExpanded}
    >
      {appointments.length === 0 ? (
        <p className="w-full py-4 text-base text-gray-600 text-center">{'Keine Einträge vorhanden'}</p>
      ) : (
        appointments.map((appointment) => {
          const projectDate = appointment.date ? new Date(appointment.date) : null
          const currentDate = new Date()

          let isCurrentOrFutureMonth = false
          if (projectDate) {
            const projectYear = projectDate.getFullYear()
            const projectMonth = projectDate.getMonth()

            const currentYear = currentDate.getFullYear()
            const currentMonth = currentDate.getMonth()

            isCurrentOrFutureMonth = projectYear > currentYear || (projectYear === currentYear && projectMonth >= currentMonth)
          }

          const filteredProjects = projects.filter(({ id }) => appointment.order_items.filter(({ project_id }) => `${project_id}` === `${id}`).length)
          const props = {
            projects: filteredProjects,
            canEditAppointment: abilities.edit_appointments && !appointment.is_deleted && isCurrentOrFutureMonth,
            canEditDelete: !!canEditDelete && !appointment.is_deleted && isCurrentOrFutureMonth,
            appointment: appointment,
            key: appointment.id,
          }
          return presentationsCouldBe ? <AppointmentsCardItemWithPresentation {...props} /> : <AppointmentsCardItem {...props} />
        })
      )}
    </SectionCard>
  )
}

export default React.memo(AppointmentsCard)
