import { Note } from './note'
import { PharmacyCluster } from './pharmacy_cluster'
import { Project } from './project'
import { Reminder } from './reminder'
import { PTA } from './pta'
import { Appointment, FinishedPresentationsResponse } from './appointment'
import { User } from './user'
import { DateString } from './generic'
export interface Pharmacies {
  pharmacies: Pharmacy[]
}

export interface PharmacyResponse {
  pharmacy: Pharmacy
}

export interface DeletePharmacyResponse {
  deletePharmacy: Pharmacy
}

export interface PharmacyAddress {
  address_name?: string
  city?: string
  zipcode?: string
  longitude?: number
  latitude?: number
}

export enum PharmacyStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export interface PharmacyActivities {
  event: string
  model: string
  timestamp: string
  user_name: string
}

interface Call {
  user: {
    name: string
  }
  created_at: DateString
}

export interface Pharmacy {
  id: number
  parent?: Pharmacy
  name?: string
  okid?: string
  address: PharmacyAddress
  phone?: string
  fax?: string
  email?: string
  appointments: Appointment[]
  contact_person?: string
  owner?: string
  url?: string
  facebook?: string
  classification?: string
  computed_state?: string
  computed_label?: string
  call: Call
  pharmacy_cluster: PharmacyCluster
  projects: Project[]
  projectbfk_id: number
  opening_times?: string
  notes?: Note[]
  tasks?: Reminder[]
  branches?: Pharmacy[]
  available_for_callcenter?: boolean
  employees?: PTA[]
  campus_registration_code?: string
  pharmacy_status?: PharmacyStatus
  reason_for_deactivation?: string
  additional_data?: string
  related_pharmacy_ids?: number[]
  isPotential?: boolean
  isPriority?: boolean
  finished_presentations?: FinishedPresentationsResponse[]
  activity_log?: PharmacyActivities[]
  gsk_classification: string
  gsk_bm: string
  open_ok_request: boolean
}
